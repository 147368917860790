import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import perfil from "../../../static/img/irstrat/perfil.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Perfil"/>
            <Banner title="Perfil"/>
            <section className="executive_area perfil">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className="ex_content">
                                <h2>¿Quiénes somos?</h2>
                                <span className="sec_border"></span>
                                <p>
                                    Somos una consultoría boutique mexicana enfocada a servir al mercado
                                    latinoamericano, para lo cual contamos
                                    con oficinas en México y los Estados Unidos. Nos especializamos en relaciones con
                                    inversionistas en su sentido
                                    integral, para alcanzar resultados sobresalientes y tangibles, cubriendo todas sus
                                    aristas: finanzas
                                    corporativas, comunicación y marketing, trading, PR estratégico y derecho
                                    financiero.
                                </p>
                                <p>Llevamos a cabo
                                    mandatos específicos como: apuntalamiento de prácticas de empresas privadas;
                                    preparación para
                                    colocaciones de renta fija; procesos pre-IPO; preparación para IPO; gestión de la
                                    percepción; y,
                                    asesoría para incremento de liquidez.</p>
                                <p>Contamos con el mejor equipo, hemos sido IROs, banqueros de inversión, traders y
                                    analistas, y hemos
                                    participado en la ejecución de complejas operaciones en el mercado latinoamericano
                                    (fusiones y adquisiciones,
                                    desincorporaciones, y colocaciones de deuda y capital).</p>
                                <p>Creemos que la mejor inversión consiste en lograr un óptimo posicionamiento y
                                    percepción en los
                                    mercados financieros, lo que a su vez es necesario para alcanzar una valuación justa
                                    y mejorar la
                                    operatividad de cualquier instrumento.

                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img className="wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                 src={perfil} alt="Perfil"/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
